import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";


/*
import translationRU from "./../assets/lang/ru.json";
import translationEN from "./../assets/lang/en.json";
import translationRO from "./../assets/lang/ro.json";
import translationFR from "./../assets/lang/fr.json";

const resources = {
  RU: {
    translation: translationRU,
    name: "Русский",
    ISO_639_1: "RU",
  },
  EN: {
    translation: translationEN,
    name: "English",
    ISO_639_1: "EN",
  },
  RO: {
    translation: translationRO,
    name: "Romanian",
    ISO_639_1: "RO",
  },
  FR: {
    translation: translationFR,
    name: "France",
    ISO_639_1: "FR",
  },
};*/

const backendOptions = {
  loadPath: '/assets/lang/{{lng}}.json',
};

i18next
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    backend: backendOptions,
    load: "languageOnly",
    fallbackLng: "en",
    /*resources: resources,*/
    debug: process.env.REACT_APP_DEBUG, 
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: [
        'path',
        'querystring',
        'cookie', 
        'header', 
        'session'
    ],
      lookupFromPathIndex: 0, // Индекс части пути для определения языка (например, 0 для /ru/ вместо /ru-ru/)
    },
  },);